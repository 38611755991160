var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.$_userMixins_isSchoolAdmin
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "kt-subheader kt-grid__item",
            attrs: { id: "kt_subheader" },
          },
          [
            _c("div", { staticClass: "kt-container kt-container--fluid" }, [
              _c("div", { staticClass: "kt-subheader__main" }, [
                _vm.deviceType !== "laptop"
                  ? _c("h3", { staticClass: "kt-subheader__title" }, [
                      _vm._v(" User Links "),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "kt-input-icon kt-input-icon--right kt-subheader__search mt-1",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchString,
                          expression: "searchString",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", placeholder: "Filter..." },
                      domProps: { value: _vm.searchString },
                      on: {
                        keyup: function ($event) {
                          _vm.currentPage = 1
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.searchString = $event.target.value
                        },
                      },
                    }),
                    _vm._m(0),
                  ]
                ),
              ]),
              _c("div", { staticClass: "kt-subheader__toolbar" }, [
                _c("div", { staticClass: "kt-subheader__wrapper mt-1" }, [
                  _c("div", { staticClass: "paginator-container" }, [
                    _c(
                      "div",
                      { staticClass: "kt-pagination kt-pagination--brand" },
                      [
                        _c(
                          "a",
                          {
                            class:
                              _vm.limitBy == "Teachers"
                                ? "btn btn-primary"
                                : "btn kt-subheader__btn-secondary",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.selectLimitBy("Teachers")
                              },
                            },
                          },
                          [_vm._v("Teachers")]
                        ),
                        _c(
                          "a",
                          {
                            class:
                              _vm.limitBy == "Students"
                                ? "btn btn-primary"
                                : "btn kt-subheader__btn-secondary",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.selectLimitBy("Students")
                              },
                            },
                          },
                          [_vm._v("Students")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "mr-3",
                            class:
                              _vm.limitBy == "Unlinked"
                                ? "btn btn-primary"
                                : "btn kt-subheader__btn-secondary",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.selectLimitBy("Unlinked")
                              },
                            },
                          },
                          [_vm._v("Unlinked")]
                        ),
                        _c("ul", { staticClass: "kt-pagination__links" }, [
                          _c("li", { staticClass: "kt-pagination__link" }, [
                            _vm.deviceType !== "mobile" && false
                              ? _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        _vm.showOptionsDropDown =
                                          !_vm.showOptionsDropDown
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "flaticon2-settings kt-font-brand",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-menu",
                                class: { show: _vm.showOptionsDropDown },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { href: "#" },
                                  },
                                  [_vm._v(" Show Teachers Only ")]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    attrs: { href: "#" },
                                  },
                                  [_vm._v(" Show Students Only ")]
                                ),
                              ]
                            ),
                          ]),
                          _vm.deviceType !== "mobile"
                            ? _c(
                                "li",
                                { staticClass: "kt-pagination__link--first" },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.first.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-angle-double-left kt-font-brand",
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "li",
                            { staticClass: "kt-pagination__link--next" },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.previous.apply(null, arguments)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-angle-left kt-font-brand",
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            { staticClass: "kt-pagination__link--prev" },
                            [
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.next.apply(null, arguments)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-angle-right kt-font-brand",
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm.deviceType !== "mobile"
                            ? _c(
                                "li",
                                { staticClass: "kt-pagination__link--last" },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.last.apply(null, arguments)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-angle-double-right kt-font-brand",
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm.deviceType !== "laptop"
                          ? _c(
                              "div",
                              { staticClass: "kt-pagination__toolbar" },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.perPage,
                                        expression: "perPage",
                                      },
                                    ],
                                    staticClass: "form-control kt-font-brand",
                                    staticStyle: { width: "60px" },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.perPage = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { domProps: { value: 100 } }, [
                                      _vm._v(" 100 "),
                                    ]),
                                    _c("option", { domProps: { value: 250 } }, [
                                      _vm._v(" 250 "),
                                    ]),
                                    _c("option", { domProps: { value: 500 } }, [
                                      _vm._v(" 500 "),
                                    ]),
                                  ]
                                ),
                                ["laptop", "desktop"].indexOf(_vm.deviceType) >
                                -1
                                  ? _c(
                                      "div",
                                      { staticClass: "pagination__desc" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.currentPage == 1
                                                ? _vm.currentPage
                                                : (_vm.currentPage - 1) *
                                                    _vm.perPage +
                                                    1
                                            ) +
                                            " - " +
                                            _vm._s(
                                              _vm.perPage * _vm.currentPage >
                                                _vm.totalRecords
                                                ? _vm.totalRecords
                                                : _vm.perPage * _vm.currentPage
                                            ) +
                                            " of " +
                                            _vm._s(_vm.totalRecords) +
                                            " users "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
          },
          [
            _c(
              "b-overlay",
              {
                attrs: {
                  variant: "transparent",
                  "no-center": "",
                  opacity: "1",
                  blur: "10px",
                  show: _vm.encryptionEnabled,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "overlay",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center pt-4 kt-font-lg" },
                            [
                              _vm._v(
                                " PII is currently encrypted. Please unlock PII to view this page. "
                              ),
                            ]
                          ),
                          _c("div", { staticClass: "text-center pt-4" }, [
                            _vm.encryptionEnabled
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-label-brand btn-bold btn-upper mt-4",
                                    staticStyle: { "min-width": "160px" },
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.decrypt()
                                      },
                                    },
                                  },
                                  [_vm._v(" Unlock PII ")]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  585768608
                ),
              },
              [
                _c(
                  "div",
                  [
                    _vm.isSetupWizard && _vm.step
                      ? _c("SetupWizardStepStatus", {
                          attrs: {
                            status: _vm.step.status,
                            message: _vm.step.message,
                          },
                        })
                      : _vm._e(),
                    _c("div", { staticClass: "kt-portlet" }, [
                      _c("div", { staticClass: "kt-portlet__body" }, [
                        _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "table table-hover" }, [
                            _c(
                              "tbody",
                              _vm._l(_vm.page, function (u) {
                                return _c("tr", { key: u.vueId }, [
                                  _c("td", { staticClass: "td-col-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "kt-ribbon kt-ribbon--clip kt-ribbon--left kt-ribbon--border-dash-hor",
                                        class: {
                                          "kt-ribbon--success":
                                            u.googleEmail &&
                                            u.portfolioType == "Student",
                                          "kt-ribbon--primary":
                                            u.googleEmail &&
                                            u.portfolioType == "Teacher",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "kt-ribbon__target" },
                                          [
                                            _c("span", {
                                              staticClass: "kt-ribbon__inner",
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(u.portfolioType) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "media mt-4 mb-3" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "kt-media kt-media--xl kt-margin-r-5 kt-margin-t-5 align-self-center mr-3",
                                          },
                                          [
                                            _c(
                                              "UserAvatar",
                                              {
                                                staticClass:
                                                  "kt-user-card-v2__pic self",
                                                staticStyle: {
                                                  "min-width": "60px",
                                                },
                                                attrs: { "avatar-user": u },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "kt-badge kt-badge--xl kt-badge--brand",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "white",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            u.lastName.substr(
                                                              0,
                                                              1
                                                            )
                                                          ) +
                                                            _vm._s(
                                                              u.firstName.substr(
                                                                0,
                                                                1
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "media-body ml-3" },
                                          [
                                            _c(
                                              "h5",
                                              { staticClass: "mt-2 mb-1" },
                                              [
                                                u.portfolioType == "Student"
                                                  ? _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "kt-widget5__title pb-0 w-100",
                                                        staticStyle: {
                                                          "white-space":
                                                            "nowrap",
                                                        },
                                                        attrs: {
                                                          to: {
                                                            name: "StudentInfo",
                                                            params: {
                                                              studentEnrollmentId:
                                                                u.studentEnrollmentId,
                                                            },
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(u.lastName) +
                                                            ", " +
                                                            _vm._s(
                                                              u.firstName
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "kt-widget4__username",
                                                        attrs: {
                                                          to: {
                                                            name: "TeacherInfo",
                                                            params: {
                                                              schoolEmail:
                                                                u.schoolEmail,
                                                            },
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(u.lastName) +
                                                            ", " +
                                                            _vm._s(
                                                              u.firstName
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                              ],
                                              1
                                            ),
                                            _c("div", { staticClass: "mt-2" }, [
                                              u.portfolioType == "Student"
                                                ? _c("span", {}, [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          u.extStudentId.substring(
                                                            0,
                                                            3
                                                          )
                                                        ) +
                                                        "-" +
                                                        _vm._s(
                                                          u.extStudentId.substring(
                                                            3,
                                                            6
                                                          )
                                                        ) +
                                                        "-" +
                                                        _vm._s(
                                                          u.extStudentId.substring(
                                                            6,
                                                            9
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              u.portfolioType == "Student"
                                                ? _c(
                                                    "span",
                                                    { staticClass: "ml-3" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(u.homeRoom) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _c("span", {}, [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(u.schoolEmail) +
                                                        " "
                                                    ),
                                                  ]),
                                            ]),
                                            _c("div", { staticClass: "mt-2" }, [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "kt-user-card-v2__name",
                                                  class: !u.googleEmail
                                                    ? "kt-font-danger"
                                                    : "",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        u.googleEmail ||
                                                          "Unlinked"
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "td",
                                    { staticClass: "td-col-2" },
                                    [
                                      _c("EmailUpdater", {
                                        attrs: {
                                          "original-email-address":
                                            u.googleEmail,
                                          "school-staff-id":
                                            u.schoolStaffId || 0,
                                          "student-enrollment-id":
                                            u.studentEnrollmentId || 0,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "span",
      { staticClass: "kt-input-icon__icon kt-input-icon__icon--right" },
      [_c("span", [_c("i", { staticClass: "flaticon2-search-1" })])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }