<template>
<div class="form-group">
    <div class="input-group mt-4">
        <input
            v-model="emailAddress"
            type="email"
            autocomplete="off"
            :disabled="saving"
            class="form-control"
            placeholder="Google Email Address"
            @keyup.enter="save"
            @focus="keepDirty = true"
        >
        <div class="input-group-append">
            <span
                class="input-group-text"
                @click.stop.prevent="save()"
            >
                <template v-if="saving">
                    <i class="kt-spinner kt-spinner--sm kt-spinner--primary" />
                </template>
                <template v-if="success">
                    <i
                        class="la la-check kt-font-success"
                    />
                    <i class="btn-success" />
                </template>
                <template v-else-if="error">
                    <i
                        class="la la-times kt-font-danger"
                    />
                </template>
                <template v-else-if="!saving">
                    <i
                        class="fa fa-save"
                    />
                </template>

            </span>
        </div>
    </div>
</div>
</template>

<script>

import { mapState } from 'vuex';
import async from 'async';
import network from '../lib/network';
import Types from '../store/Types';

export default {
    name: 'EmailUpdater',
    props: {
        originalEmailAddress: {
            type: String,
            required: false,
            default: '',
        },
        schoolStaffId: {
            type: [String, Number],
            required: true,
        },
        studentEnrollmentId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            saving: false,
            keepDirty: false,
            emailAddress: '',
            successMessage: null,
            errorMessage: null,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        userType() {
            return this.schoolStaffId ? 'Teacher' : 'Student';
        },
        isDirty() {
            return this.originalEmailAddress !== this.emailAddress || this.keepDirty;
        },
        myGoogleDomain() {
            if (!this.user.googleEmail) return null;
            return this.user.googleEmail.split('@')[1].toLowerCase();
        },
        thisGoogleDomain() {
            if (!this.isValidEmail) return null;
            return this.emailAddress.split('@')[1].toLowerCase();
        },
        success() {
            return this.successMessage || null;
        },
        error() {
            return this.errorMessage || null;
        },
        isValidEmail() {
            let { emailAddress } = this;
            emailAddress = emailAddress || '';
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(emailAddress.toLowerCase());
        },
        isValidDomain() {
            if (!this.isValidEmail) return false;
            return this.myGoogleDomain == this.thisGoogleDomain;
        },
    },
    mounted() {
        this.emailAddress = this.originalEmailAddress;
    },
    methods: {
        save() {
            const v = this;

            v.keepDirty = true;
            v.successMessage = null;
            v.errorMessage = null;

            if (v.saving) return;

            v.saving = true;

            const {
                schoolStaffId, studentEnrollmentId,
                user, userType,
            } = v;

            let { emailAddress } = v;
            emailAddress = emailAddress.toLowerCase();

            async.auto({
                validate(next) {
                    if (!v.isValidDomain) return next('Invalid domain for this school');
                    if (!v.isValidEmail) return next('Please enter a valid email address.');
                    next();
                },
                student: ['validate', function (results, next) {
                    if (userType == 'Teacher') return next();
                    const { students } = v.$store.state.database;
                    const student = students.find((s) => s.studentEnrollmentId == studentEnrollmentId);
                    network.setGoogleEmailForStudent(user, student.studentId, emailAddress, next);
                }],
                teacher: ['validate', function (results, next) {
                    if (userType == 'Student') return next();
                    network.setGoogleEmailForTeacher(user, schoolStaffId, emailAddress, next);
                }],
            }, 10, (err) => {
                v.saving = false;

                if (err) {
                    v.errorMessage = err;
                    v.showError(err);
                    return;
                }

                if (userType == 'Teacher') {
                    v.$store.commit(Types.mutations.EDIT_TEACHER_EMAIL, { schoolStaffId, emailAddress });
                } else {
                    v.$store.commit(Types.mutations.EDIT_STUDENT_EMAIL, { studentEnrollmentId, emailAddress });
                }
                v.successMessage = `Saved ${v.emailAddress}`;
                setTimeout(() => {
                    v.successMessage = null;
                    v.errorMessage = null;
                }, 500);
            });
        },
    },
};

</script>

<style scoped>
input.form-control {
    min-width: 160px;
}

span.input-group-text {
    width: 42px;
}
</style>
