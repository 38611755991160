var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c("div", { staticClass: "input-group mt-4" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.emailAddress,
            expression: "emailAddress",
          },
        ],
        staticClass: "form-control",
        attrs: {
          type: "email",
          autocomplete: "off",
          disabled: _vm.saving,
          placeholder: "Google Email Address",
        },
        domProps: { value: _vm.emailAddress },
        on: {
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.save.apply(null, arguments)
          },
          focus: function ($event) {
            _vm.keepDirty = true
          },
          input: function ($event) {
            if ($event.target.composing) return
            _vm.emailAddress = $event.target.value
          },
        },
      }),
      _c("div", { staticClass: "input-group-append" }, [
        _c(
          "span",
          {
            staticClass: "input-group-text",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.save()
              },
            },
          },
          [
            _vm.saving
              ? [
                  _c("i", {
                    staticClass:
                      "kt-spinner kt-spinner--sm kt-spinner--primary",
                  }),
                ]
              : _vm._e(),
            _vm.success
              ? [
                  _c("i", { staticClass: "la la-check kt-font-success" }),
                  _c("i", { staticClass: "btn-success" }),
                ]
              : _vm.error
              ? [_c("i", { staticClass: "la la-times kt-font-danger" })]
              : !_vm.saving
              ? [_c("i", { staticClass: "fa fa-save" })]
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }