<template>
<div v-if="$_userMixins_isSchoolAdmin">
    <div id="kt_subheader" class="kt-subheader kt-grid__item">
        <div class="kt-container kt-container--fluid">
            <div class="kt-subheader__main">
                <h3 v-if="deviceType !== 'laptop'" class="kt-subheader__title">
                    User Links
                </h3>
                <div class="kt-input-icon kt-input-icon--right kt-subheader__search mt-1">
                    <input
                        v-model="searchString"
                        type="text"
                        class="form-control"
                        placeholder="Filter..."
                        @keyup="currentPage = 1"
                    >
                    <span class="kt-input-icon__icon kt-input-icon__icon--right">
                        <span>
                            <i class="flaticon2-search-1" />
                        </span>
                    </span>
                </div>
            </div>

            <div class="kt-subheader__toolbar">
                <div class="kt-subheader__wrapper mt-1">
                    <div class="paginator-container">
                        <div class="kt-pagination kt-pagination--brand">
                            <a
                                :class="limitBy == 'Teachers' ? 'btn btn-primary' : 'btn kt-subheader__btn-secondary'"
                                @click.stop.prevent="selectLimitBy('Teachers')"
                            >Teachers</a>
                            <a
                                :class="limitBy == 'Students' ? 'btn btn-primary' : 'btn kt-subheader__btn-secondary'"
                                @click.stop.prevent="selectLimitBy('Students')"
                            >Students</a>
                            <a
                                class="mr-3"
                                :class="limitBy == 'Unlinked' ? 'btn btn-primary' : 'btn kt-subheader__btn-secondary'"
                                @click.stop.prevent="selectLimitBy('Unlinked')"
                            >Unlinked</a>

                            <ul class="kt-pagination__links">
                                <li class="kt-pagination__link">
                                    <a
                                        v-if="deviceType !== 'mobile' && false"
                                        href="#"
                                        @click.stop.prevent="showOptionsDropDown = !showOptionsDropDown"
                                    >
                                        <i class="flaticon2-settings kt-font-brand" />
                                    </a>
                                    <div :class="{ 'show': showOptionsDropDown }" class="dropdown-menu">
                                        <a class="dropdown-item" href="#">
                                            Show Teachers Only
                                        </a>

                                        <a class="dropdown-item" href="#">
                                            Show Students Only
                                        </a>
                                    </div>
                                </li>
                                <li v-if="deviceType !== 'mobile'" class="kt-pagination__link--first">
                                    <a href="#" @click.stop.prevent="first"><i
                                        class="fa fa-angle-double-left kt-font-brand"
                                    /></a>
                                </li>
                                <li class="kt-pagination__link--next">
                                    <a href="#" @click.stop.prevent="previous"><i
                                        class="fa fa-angle-left kt-font-brand"
                                    /></a>
                                </li>

                                <li class="kt-pagination__link--prev">
                                    <a href="#" @click.stop.prevent="next"><i
                                        class="fa fa-angle-right kt-font-brand"
                                    /></a>
                                </li>
                                <li v-if="deviceType !== 'mobile'" class="kt-pagination__link--last">
                                    <a href="#" @click.stop.prevent="last"><i
                                        class="fa fa-angle-double-right kt-font-brand"
                                    /></a>
                                </li>
                            </ul>
                            <div v-if="deviceType !== 'laptop'" class="kt-pagination__toolbar">
                                <select
                                    v-model="perPage"
                                    class="form-control kt-font-brand"
                                    style="width: 60px;"
                                >
                                    <option :value="100">
                                        100
                                    </option>
                                    <option :value="250">
                                        250
                                    </option>
                                    <option :value="500">
                                        500
                                    </option>
                                </select>

                                <div v-if="['laptop', 'desktop'].indexOf(deviceType) > -1" class="pagination__desc">
                                    {{ currentPage == 1 ? currentPage : (currentPage - 1) * perPage + 1 }}
                                    -
                                    {{ perPage * currentPage > totalRecords ? totalRecords : perPage * currentPage }}
                                    of
                                    {{ totalRecords }}
                                    users
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <b-overlay
            :variant="'transparent'"
            no-center
            opacity="1"
            blur="10px"
            :show="encryptionEnabled"
        >
            <template #overlay>
                <div class="text-center pt-4 kt-font-lg">
                    PII is currently encrypted. Please unlock PII to view this page.
                </div>
                <div class="text-center pt-4">
                    <button
                        v-if="encryptionEnabled"
                        type="button"
                        style="min-width: 160px;"
                        class="btn btn-label-brand btn-bold btn-upper mt-4"
                        @click="decrypt()"
                    >
                        Unlock PII
                    </button>
                </div>
            </template>
            <div>
                <SetupWizardStepStatus
                    v-if="isSetupWizard && step"
                    :status="step.status"
                    :message="step.message"
                />

                <div class="kt-portlet">
                    <div class="kt-portlet__body">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <tbody>
                                    <tr v-for="u in page" :key="u.vueId">
                                        <td class="td-col-1">
                                            <div
                                                class="kt-ribbon kt-ribbon--clip  kt-ribbon--left kt-ribbon--border-dash-hor"
                                                :class="{
                                                    'kt-ribbon--success': u.googleEmail && u.portfolioType == 'Student',
                                                    'kt-ribbon--primary': u.googleEmail && u.portfolioType == 'Teacher',
                                                }"
                                            >
                                                <div class="kt-ribbon__target">
                                                    <span class="kt-ribbon__inner" />
                                                    {{ u.portfolioType }}
                                                </div>
                                            </div>
                                            <div class="media mt-4 mb-3">
                                                <span class="kt-media kt-media--xl kt-margin-r-5 kt-margin-t-5 align-self-center mr-3">
                                                    <UserAvatar
                                                        class="kt-user-card-v2__pic self"
                                                        style="min-width: 60px;"
                                                        :avatar-user="u"
                                                    >
                                                        <div class="kt-badge kt-badge--xl kt-badge--brand">
                                                            <span style="color: white">{{ u.lastName.substr(0, 1) }}{{
                                                                u.firstName.substr(0, 1) }}</span>
                                                        </div>
                                                    </UserAvatar>
                                                </span>
                                                <div class="media-body ml-3">
                                                    <h5 class="mt-2 mb-1">
                                                        <router-link
                                                            v-if="u.portfolioType == 'Student'"
                                                            style="white-space: nowrap;"
                                                            class="kt-widget5__title pb-0 w-100"
                                                            :to="{
                                                                name: 'StudentInfo',
                                                                params: {
                                                                    studentEnrollmentId: u.studentEnrollmentId,
                                                                },
                                                            }"
                                                        >
                                                            {{ u.lastName }}, {{ u.firstName }}
                                                        </router-link>
                                                        <router-link
                                                            v-else
                                                            :to="{ name: 'TeacherInfo', params: { schoolEmail: u.schoolEmail }}"
                                                            class="kt-widget4__username"
                                                        >
                                                            {{ u.lastName }}, {{ u.firstName }}
                                                        </router-link>
                                                    </h5>
                                                    <div class="mt-2">
                                                        <span v-if="u.portfolioType == 'Student'" class="">
                                                            {{ u.extStudentId.substring(0, 3) }}-{{
                                                                u.extStudentId.substring(3, 6) }}-{{
                                                                u.extStudentId.substring(6, 9) }}
                                                        </span>
                                                        <span v-if="u.portfolioType == 'Student'" class="ml-3">
                                                            {{ u.homeRoom }}
                                                        </span>
                                                        <span v-else class="">
                                                            {{ u.schoolEmail }}
                                                        </span>
                                                    </div>
                                                    <div class="mt-2">
                                                        <span
                                                            class="kt-user-card-v2__name"
                                                            :class="!u.googleEmail ? 'kt-font-danger' : ''"
                                                        >
                                                            {{ u.googleEmail || 'Unlinked' }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="td-col-2">
                                            <EmailUpdater
                                                :original-email-address="u.googleEmail"
                                                :school-staff-id="u.schoolStaffId || 0"
                                                :student-enrollment-id="u.studentEnrollmentId || 0"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</div>
</template>

<script>

import UserAvatar from '../../components/UserAvatar.vue';
import EmailUpdater from '../../components/EmailUpdater.vue';
import SetupWizardStepStatus from '../../components/SetupWizardStepStatus.vue';
import userMixins from '../../store/mixins/userMixins';
import { getStudents } from '../../store/mixins/studentMixins';
import Types from '../../store/Types';
import Vue from 'vue';

export default Vue.extend({
    name: 'GoogleUserLink',
    components: {
        UserAvatar,
        EmailUpdater,
        SetupWizardStepStatus,
    },
    mixins: [
        userMixins,
    ],
    data() {
        return {
            perPage: 100,
            currentPage: 1,
            searchString: '',
            optionsHovering: false,
            showOptionsDropDown: false,
            limitBy: 'Teachers',
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        isSetupWizard() {
            return this.$route.name == 'SetupWizard';
        },
        steps() {
            return this.$store.state.wizard.steps;
        },
        step() {
            return this.steps.find((r) => r.routeName == 'SetupGoogleArchive');
        },
        deviceType() {
            return this.$store.state.deviceType;
        },
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        isSetupWizard() {
            return this.$route.name == 'SetupUserLinking';
        },
        totalRecords() {
            return this.users.length;
        },
        page() {
            return this.users.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage,
            );
        },
        totalPages() {
            return Math.ceil(this.totalRecords / this.perPage);
        },
        rows() {
            return this.users.length;
        },
        users() {
            let { searchString } = this;
            const { limitBy } = this;
            searchString = (searchString || '').toLowerCase();

            const arraySortOrder = function (a, b) {
                if (a.sort < b.sort) return -1;
                if (a.sort > b.sort) return 1;
                return 0;
            };
            const teachers = this.$store.state.database.teachers;
            const students = getStudents(this.$store.state.database);
            let array = [...teachers, ...students];
            if (limitBy == 'Teachers') array = [...teachers];
            if (limitBy == 'Students') array = [...students];

            const output = array
                .filter((user) => {
                    if (limitBy !== 'Unlinked') return true;
                    if (!user.googleEmail) return true;
                    return false;
                })
                .map((u) => {
                    const user = u;
                    user.portfolioType = user.schoolStaffId ? 'Teacher' : 'Student';
                    user.vueId = `${user.portfolioType}_${user.schoolStaffId || user.studentEnrollmentId}`;
                    user.sort = `${user.lastName} ${user.firstName}`;
                    user.search = `${user.firstName} ${user.lastName} ${user.schoolEmail} ${user.googleEmailAddress} ${user.extStudentId || ''}`.toLowerCase();
                    return user;
                })
                .filter((t) => (searchString ? t.search.indexOf(searchString.toLowerCase()) > -1 : true))
                .sort(arraySortOrder);
            return output;
        },
    },
    methods: {
        decrypt() {
            this.$store.commit(Types.mutations.SHOW_SCHOOL_PASSWORD_MODAL, true);
        },
        first() {
            this.currentPage = 1;
        },
        last() {
            this.currentPage = this.totalPages;
        },
        previous() {
            if (this.currentPage > 1) {
                this.currentPage -= 1;
            }
        },
        next() {
            if (this.currentPage < this.totalPages) {
                this.currentPage += 1;
            }
        },
        selectLimitBy(t) {
            if (this.limitBy == t) {
                this.limitBy = null;
                return;
            }
            this.limitBy = t;
        },
    },
});



</script>

<style scoped>
td.td-col-1 {
    min-width: 200px;
}

td.td-col-2 {
    min-width: 260px;
}

.kt-ribbon__target {
    top: 0px;
    left: -10px;
    font-weight: 500;
}

div.pagination__desc {
    min-width: 140px;
    text-align: right;
}
</style>
